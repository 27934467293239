@tailwind base;
@tailwind components;
@tailwind utilities;

html * {
  font-family: 'Poppins', sans-serif;
}

img {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}


/*
main {
  max-width: 80rem;
  padding: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0;
}
*/

.bg-gradient {
  background: rgb(0, 67, 255);
  background: linear-gradient(
    90deg,
    rgba(0, 67, 255, 1) 0%,
    rgba(18, 39, 79, 1) 100%
  );
}

.bg-gradient-white-blue {
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(0, 67, 255) 100%);
}

.bg-gradient-blue-white {
  background: linear-gradient(90deg, rgb(0, 67, 255) 0%, rgb(255, 255, 255) 100%);
}



.bg-gradient-reverse {
  background: rgb(0, 67, 255);
  background: linear-gradient(rgba(0, 67, 255, 1) 0%, rgba(18, 39, 79, 1) 100%);
}

.bg-gradient-hover:hover {
  background: rgb(0, 67, 255);
  background: linear-gradient(
    30deg,
    rgba(0, 67, 255, 1) 0%,
    rgba(18, 39, 79, 1) 100%
  );
}

.bg-gradient-footer {
  background: rgb(0, 67, 255);
  background: linear-gradient(
    30deg,
    rgba(0, 67, 255, 1) 0%,
    rgba(18, 39, 79, 1) 100%
  );
}

.bg-rebin-grey {
  background-color: #f2f2f2;
}

.bg-rebin-dark-blue {
  background-color: #12274F;
}

.bg-rebin-blue {
  background-color: #0043ff;
}

.bg-rebin-light-blue {
  background-color: #F2F5FD;
}

.bg-rebin-light-blue-hover:hover {
  background-color: #F2F5FD;
}

.divide-rebin-darkblue {
  border-color: rgba(18, 39, 79, 0.2);
}

.text-gradient {
  background: -webkit-linear-gradient(#0043ff, #12274f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: fit-content;
}

.text-gradient-horizontal {
  background: -webkit-linear-gradient(45deg, #0043ff, #12274f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
}

.text-rebin-darkblue {
  color: #12274f;
}

.text-rebin-blue {
  color: #0043ff;
}

.text-gradient-hover:hover {
  background: -webkit-linear-gradient(left, #0043ff, #12274f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: semi-bold;
}

.border-rebin-darkblue {
  border-style: solid;
  border-color: #12274f;
}

.border-rebin-blue {
  border-style: solid;
  border-color: #0c3192;
}

.border-t-rebin-blue {
  border-style: solid;
  border-top-color: #0c3192;
}

/* SLIDER */
.slick-dots {
  bottom: -50px;
}

.blob {
  height: 150px;
  width: 150px;
  background-color: #0043ff;
  filter: blur(80px);
}

/* Default styles for small screens */
.blob-large-screen {
  height: 200px; /* Adjusted for smaller screens */
  width: 300px;
  background-color: #0043ff;
  filter: blur(50px); /* Reduced blur for smaller screens */
}

/* Apply styles for larger screens */
@media (min-width: 768px) {
  .blob-large-screen {
    height: 300px;
    width: 500px;
    filter: blur(80px);
  }
}

.blob-small-screen {
  height: 300px;
  width: 100px;
  background-color: #0043ff;
  filter: blur(80px);
}



.blob3 {
  height: 200px;
  width: 300px;
  background-color: #0043ff;
  filter: blur(100px);
}

.slick-active > a > div {
  background: linear-gradient(
    90deg,
    rgba(0, 67, 255, 1) 0%,
    rgba(18, 39, 79, 1) 100%
  );
}

/* prevent image dragging */
img {
  pointer-events: none;
}

.border-rebin {
  border-style: solid;
  border-width: 1px;
  border-color: #dee5ec;
  outline-color: #0043ff;
}

.border-rebin-blue-color {
  border-style: solid;
  border-width: 1px;
  border-color: #0043ff;
  outline-color: #0043ff;
}



.special-button:hover {
  transform: scale(1.04);
  transition: transform 0.2s ease-in-out;
}


